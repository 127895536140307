















































































































import { Component, Mixins } from "vue-property-decorator";
import { base_class_pantalla } from "@/shared/classes/base-class-pantallas";
import { RouterNames } from "@/router/routernames";
import preguntasModule from "@/store/modules/preguntas-module";
import mapping_questionModule from "@/store/modules/mapping_question-module";
import { preguntas } from "@/shared/dtos/preguntas";
@Component({
  components: {
    MaterialCard: () => import("@/components/Cards/MaterialCard.vue"),
  },
})
export default class config_mapeador_formulario extends Mixins(
  base_class_pantalla
) {
  created() {
    this.nombre_pantalla = "Mapeo de datos";
    this.toolbar_texto_editar = "Editar mapeado";
    this.toolbar_texto_crear = "Crear nuevo mapeado";
    this.es_edicion = Number.parseInt(this.$route.params.id) > -1;
    if (this.es_edicion) {
      preguntasModule.getpreguntas(Number.parseInt(this.$route.params.id));
    }else{
      preguntasModule.onGetpreguntas(new preguntas());
    }
    mapping_questionModule.getmapping_questions();
  }
  public get datapregunta() {
    return preguntasModule.preguntas;
  }
  public get mapping_questions() {
    return mapping_questionModule.mapping_questions;
  }
  public submit() {
    if (this.es_edicion) {
      preguntasModule
        .modificarpreguntas(this.datapregunta)
        .then(() => this.toList());
    } else {
      this.datapregunta.id = -1;
      preguntasModule
        .guardar_mapeo_de_pregunta(this.datapregunta)
        .then(() => this.toList());
    }
  }
  public eliminar() {
    preguntasModule
      .eliminarpreguntas(preguntasModule.pregunta_entera.preguntas)
      .then(() => this.toList());
  }
  public toList() {
    this.$router.push({ name: RouterNames.mapeadorlista });
  }
}
