import { store } from "@/store/store";
import { mapping_question } from "@/shared/dtos/mapping_question";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";

@Module({
  namespaced: true,
  name: "mapping_questionModule",
  store,
  dynamic: true,
})
class mapping_questionModule extends VuexModule {
  public mapping_questions: mapping_question[] = [];
  public mapping_question: mapping_question = new mapping_question();

  @Action({ commit: "onGetmapping_questions" })
  public async getmapping_questions() {
    return await ssmHttpService.get(API.mapping_question);
  }

  @Action({ commit: "onGetmapping_question" })
  public async getmapping_question(id: any) {
    return await ssmHttpService.get(API.mapping_question + "/" + id);
  }

  @Mutation
  public onGetmapping_questions(res: mapping_question[]) {
    this.mapping_questions = res ? res.map((x) => new mapping_question(x)) : [];
  }

  @Mutation
  public onGetmapping_question(res: mapping_question) {
    this.mapping_question = new mapping_question(res);
  }
}
export default getModule(mapping_questionModule);
